<template>
  <div class="card">
    <!-- Ecran formulaire de création -->
    <div class="card-header">
      <div class="card-header_title">{{ stepLabel }}</div>
    </div>
    <form @submit.prevent="submit">
      <div class="card-content">
        <div class="content">
          <component
            :is="currentStep.component"
            :subscription="subscription"
            v-model="forms[currentStepIndex - 1]"
            can-edit
            :has-second-user="hasSecondUser"
            @edit="onEdit"
          ></component>
        </div>
      </div>

      <a
        ref="linkPdfUrl"
        v-if="pdfUrl"
        :href="pdfUrl"
        target="_blank"
        rel="noopener noreferrer"
        >Télécharger le PDF</a
      >

      <footer class="card-footer">
        <b-button
          class="card-footer-item"
          type="is-light"
          native-type="button"
          :disabled="pending || isFirstStep"
          @click="goPreviousStep()"
          >Précédent</b-button
        >
        <b-button
          class="card-footer-item"
          type="is-primary"
          native-type="submit"
          :disabled="pending"
          >{{
            isLastStep ? 'Enregistrer et générer le PDF' : 'Suivant'
          }}</b-button
        >
      </footer>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SubscriptionPackForm from './subscription-pack-form.vue'
import SubscriptionPackShow from './subscription-pack-show.vue'
import SubscriptionUsersForm from './subscription-users-form.vue'
import SubscriptionInfosForm from './subscription-infos-form.vue'

export default {
  components: {
    SubscriptionPackForm,
    SubscriptionPackShow,
    SubscriptionUsersForm,
    SubscriptionInfosForm,
  },
  props: {
    client: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    let [postal_code, city] = this.client.postcode_city.split(' - ')

    return {
      pending: false,
      pdfUrl: null,
      currentStepIndex: 1,
      subscription: null,
      forms: [
        {
          pack_id: null,
          installation_id: null,
          options: [],
          benefits: [],
          final_price_installation: 0,
          final_price_subscription: 0,
        },
        {
          user_id_grc: this.client.idgrc,
          user_civility:
            this.client.civility === 'MAN'
              ? 'Mr'
              : this.client.civility === 'WOMAN'
              ? 'Mme'
              : null,
          user_first_name: this.client.firstname,
          user_last_name: this.client.lastname,
          user_address_line_1: this.client.address,
          user_address_line_2: null,
          user_postal_code: postal_code,
          user_city: city,
          user_phone: this.client.phone,
          user_mobile: null,
          user_birth_date: null,
          user_is_subscriber: true,
          subscriber_civility: null,
          subscriber_first_name: null,
          subscriber_last_name: null,
          subscriber_address_line_1: null,
          subscriber_address_line_2: null,
          subscriber_postal_code: null,
          subscriber_city: null,
          subscriber_phone: null,
          subscriber_email: null,
          second_user_civility: null,
          second_user_first_name: null,
          second_user_last_name: null,
          second_user_phone: null,
          second_user_mobile: null,
          second_user_birth_date: null,
          bank_bic: null,
          bank_iban: null,
        },
        {
          property_type: null,
          property_access_code: null,
          property_floor: null,
          property_number: null,
          property_details: null,
          contacts: [
            {
              civility: null,
              first_name: null,
              last_name: null,
              phone: null,
              travel_time: null,
              has_home_keys: false,
              is_parent: false,
            },
            {
              civility: null,
              first_name: null,
              last_name: null,
              phone: null,
              travel_time: null,
              has_home_keys: false,
              is_parent: false,
            },
          ],
        },
      ],
      state: {
        errors: {},
      },
    }
  },
  provide() {
    return {
      state: this.state,
    }
  },
  mounted() {
    this.loadExistingSubscription()
  },
  computed: {
    ...mapState({
      equipment: (state) => state.equipment,
    }),
    hasSecondUser() {
      // Gestion des options pas terrible terrible...
      return (
        this.subscription?.options?.second_fall_detector?.checked ||
        this.forms[0].options.includes('second_fall_detector') ||
        false
      )
    },
    currentStep() {
      return this.steps[this.currentStepIndex - 1]
    },
    steps() {
      return [
        {
          label: 'Choix de la formule',
          component: this.subscription
            ? 'SubscriptionPackShow'
            : 'SubscriptionPackForm',
          endpoint: this.subscription ? null : 'pack',
          form: 'formPack',
        },
        {
          label: 'Informations administratives',
          component: 'SubscriptionUsersForm',
          endpoint: 'users',
          form: 'formUsers',
        },
        {
          label: 'Fiche de renseignements',
          component: 'SubscriptionInfosForm',
          endpoint: 'infos',
          form: 'formInfos',
        },
      ]
    },
    stepLabel() {
      return `Etape ${this.currentStepIndex} : ${this.currentStep.label}`
    },
    isFirstStep() {
      return this.currentStepIndex === 1
    },
    isLastStep() {
      return this.currentStepIndex === this.steps.length
    },
  },
  methods: {
    onEdit() {
      if (this.currentStepIndex === 1) {
        this.subscription = null
      }
    },
    async loadExistingSubscription() {
      if (!this.equipment.subscription) {
        return
      }

      this.subscription = this.equipment.subscription

      // On exclu le premier formulaire qui est le choix de la formule
      // Il n'est en effet plus réapplicable
      for (let i = 1; i <= this.forms.length - 1; i++) {
        let data = Object.keys(this.forms[i]).reduce((acc, key) => {
          acc[key] = this.subscription[key] || this.forms[i][key]
          return acc
        }, {})

        this.forms = [
          ...this.forms.slice(0, i),
          data,
          ...this.forms.slice(i + 1),
        ]
      }
    },
    goPreviousStep() {
      this.currentStepIndex--
    },
    goNextStep() {
      this.currentStepIndex++
    },
    async submit() {
      let endpoint = this.currentStep.endpoint

      if (!endpoint) {
        this.goNextStep()
        return
      }

      this.pending = true

      try {
        await this.$axios.patch(
          `/api/equipments/${this.equipment.id}/subscription/${endpoint}`,
          this.forms[this.currentStepIndex - 1]
        )

        this.state.errors = {}

        if (!this.isLastStep) {
          this.goNextStep()
          return
        }

        let { data } = await this.$axios.post(
          `/api/equipments/${this.equipment.id}/subscription/pdf`
        )

        this.$buefy.toast.open({
          message: 'Souscription enregistrée avec succès',
          type: 'is-success',
          position: 'is-bottom',
          duration: 3000,
        })

        this.pdfUrl = data.pdf_url
        this.$nextTick(() => {
          this.$refs.linkPdfUrl.click()
        })
        this.$emit('refresh')
      } catch (e) {
        if (e.response && e.response.status === 422) {
          this.state.errors = e.response.data.errors
        }
      } finally {
        this.pending = false
      }
    },
  },
}
</script>
