<template>
  <div class="card p-5">
    <!-- Ecran de lecture -->
    <div class="card-header">
      <div class="card-header_title">Contrat TAP</div>
    </div>
    <div>
      <SubscriptionPackShow :subscription="subscription"></SubscriptionPackShow>
      <div>
        <button class="button is-primary" @click="isModalDetailActive = true">
          Voir +
        </button>
        <button class="button ml-2" @click="isModalFormActive = true">
          Editer fiche de renseignements
        </button>
      </div>
    </div>
    <b-modal :active.sync="isModalDetailActive" has-modal-card>
      <div class="modal-card">
        <div class="modal-card-body">
          <div class="columns">
            <div class="column">
              <fieldset>
                <legend>Utilisateur principal</legend>
                <div>
                  <label class="label">Civilité :</label>
                  <span>{{ subscription.user_civility }}</span>
                </div>
                <div>
                  <label class="label">Nom :</label>
                  <span>{{ subscription.user_last_name }}</span>
                </div>
                <div>
                  <label class="label">Prénom :</label>
                  <span>{{ subscription.user_first_name }}</span>
                </div>
                <div>
                  <label class="label">Adresse :</label>
                  <span>{{ subscription.user_address_line_1 }}</span>
                  <span>{{ subscription.user_address_line_2 }}</span>
                </div>
                <div>
                  <label class="label">Code postal :</label>
                  <span>{{ subscription.user_postal_code }}</span>
                </div>
                <div>
                  <label class="label">Ville :</label>
                  <span>{{ subscription.user_city }}</span>
                </div>
                <div>
                  <label class="label">Téléphone :</label>
                  <span>{{ subscription.user_phone }}</span>
                </div>
                <div>
                  <label class="label">Mobile :</label>
                  <span>{{ subscription.user_mobile }}</span>
                </div>
                <div>
                  <label class="label">Date de naissance :</label>
                  <span>{{ subscription.user_birth_date }}</span>
                </div>
                <div>
                  <label class="label">Est souscripteur :</label>
                  <span>{{
                    subscription.user_is_subscriber ? 'Oui' : 'Non'
                  }}</span>
                </div>
              </fieldset>

              <fieldset v-if="!subscription.user_is_subscriber">
                <legend>Souscripteur</legend>
                <div>
                  <label class="label">Civilité :</label>
                  <span>{{ subscription.subscriber_civility }}</span>
                </div>
                <div>
                  <label class="label">Nom :</label>
                  <span>{{ subscription.subscriber_last_name }}</span>
                </div>
                <div>
                  <label class="label">Prénom :</label>
                  <span>{{ subscription.subscriber_first_name }}</span>
                </div>
                <div>
                  <label class="label">Adresse :</label>
                  <span>{{ subscription.subscriber_address_line_1 }}</span>
                  <span>{{ subscription.subscriber_address_line_2 }}</span>
                </div>
                <div>
                  <label class="label">Code postal :</label>
                  <span>{{ subscription.subscriber_postal_code }}</span>
                </div>
                <div>
                  <label class="label">Ville :</label>
                  <span>{{ subscription.subscriber_city }}</span>
                </div>
              </fieldset>

              <fieldset>
                <legend>Second utilisateur</legend>
                <div>
                  <label class="label">Civilité :</label>
                  <span>{{ subscription.second_user_civility }}</span>
                </div>
                <div>
                  <label class="label">Nom :</label>
                  <span>{{ subscription.second_user_last_name }}</span>
                </div>
                <div>
                  <label class="label">Prénom :</label>
                  <span>{{ subscription.second_user_first_name }}</span>
                </div>
                <div>
                  <label class="label">Téléphone :</label>
                  <span>{{ subscription.second_user_phone }}</span>
                </div>
                <div>
                  <label class="label">Mobile :</label>
                  <span>{{ subscription.second_user_mobile }}</span>
                </div>
                <div>
                  <label class="label">Date de naissance :</label>
                  <span>{{ subscription.second_user_birth_date }}</span>
                </div>
              </fieldset>

              <fieldset>
                <legend>Informations bancaire</legend>
                <div>
                  <label class="label">BIC :</label>
                  <span>{{ subscription.bank_bic }}</span>
                </div>
                <div>
                  <label class="label">IBAN :</label>
                  <span>{{ subscription.bank_iban }}</span>
                </div>
              </fieldset>
            </div>

            <div class="column">
              <fieldset>
                <legend>Accès habitation</legend>
                <div>
                  <label class="label">Type :</label>
                  <span>{{ propertyTypes[subscription.property_type] }}</span>
                </div>
                <div v-if="subscription.property_type === 'APARTMENT'">
                  <div>
                    <label class="label">Code d'accès :</label>
                    <span>{{ subscription.property_access_code }}</span>
                  </div>
                  <div>
                    <label class="label">Etage :</label>
                    <span>{{ subscription.property_floor }}</span>
                  </div>
                  <div>
                    <label class="label">Numéro d'appartement :</label>
                    <span>{{ subscription.property_number }}</span>
                  </div>
                  <div>
                    <label class="label">Autres précisions :</label>
                    <span>{{ subscription.property_details }}</span>
                  </div>
                </div>
              </fieldset>

              <fieldset v-for="(contact, i) in subscription.contacts" :key="i">
                <legend>Contact n°{{ i + 1 }}</legend>
                <div>
                  <label class="label">Civilité :</label>
                  <span>{{ contact.civility }}</span>
                </div>
                <div>
                  <label class="label">Nom :</label>
                  <span>{{ contact.last_name }}</span>
                </div>
                <div>
                  <label class="label">Prénom :</label>
                  <span>{{ contact.first_name }}</span>
                </div>
                <div>
                  <label class="label">Téléphone :</label>
                  <span>{{ contact.phone }}</span>
                </div>
                <div>
                  <label class="label">Temps de trajet :</label>
                  <span>{{ contact.travel_time }}</span>
                </div>
                <div>
                  <label class="label">Dispose des clés :</label>
                  <span>{{ contact.has_home_keys ? 'Oui' : 'Non' }}</span>
                </div>
                <div>
                  <label class="label">Parent de l'utilisateur :</label>
                  <span>{{ contact.is_parent ? 'Oui' : 'Non' }}</span>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal :active.sync="isModalFormActive" has-modal-card>
      <div class="modal-card">
        <form class="modal-card-body" @submit.prevent="submit">
          <SubscriptionInfosForm v-model="form"></SubscriptionInfosForm>

          <div class="buttons">
            <b-button
              native-type="submit"
              :loading="pending"
              class="is-primary"
            >
              Enregistrer
            </b-button>
            <button
              type="button"
              class="button"
              @click="isModalFormActive = false"
            >
              Annuler
            </button>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SubscriptionInfosForm from './subscription-infos-form.vue'
import SubscriptionPackShow from './subscription-pack-show.vue'

export default {
  components: {
    SubscriptionInfosForm,
    SubscriptionPackShow,
  },
  props: {
    equipmentId: {
      type: Number,
      required: true,
    },
    subscription: {
      type: Object,
      required: true,
    },
  },
  provide() {
    return {
      state: this.state,
    }
  },
  data() {
    return {
      isModalDetailActive: false,
      isModalFormActive: false,
      pending: false,
      form: {},
      state: {
        errors: {},
      },
    }
  },
  computed: {
    ...mapState({
      priceTypes: (state) => state.enums.priceTypes,
      propertyTypes: (state) => state.enums.propertyTypes,
    }),
  },
  watch: {
    subscription: {
      handler() {
        const getSubsetDeepCopy = (obj, keys) => {
          return keys.reduce((subset, key) => {
            if (key in obj) {
              subset[key] = JSON.parse(JSON.stringify(obj[key]))
            }
            return subset
          }, {})
        }

        this.form = getSubsetDeepCopy(this.subscription, [
          'property_type',
          'property_access_code',
          'property_floor',
          'property_number',
          'property_details',
          'contacts',
        ])
      },
      immediate: true,
    },
  },
  methods: {
    async submit() {
      this.pending = true

      try {
        await this.$axios.patch(
          `/api/equipments/${this.equipmentId}/subscription/infos`,
          this.form
        )

        this.isModalFormActive = false

        this.$buefy.toast.open({
          message: 'Renseignements enregistrés avec succès',
          type: 'is-success',
          position: 'is-bottom',
          duration: 3000,
        })

        this.$emit('refresh')
      } catch (e) {
        if (e.response && e.response.status === 422) {
          this.state.errors = e.response.data.errors
        }
      } finally {
        this.pending = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.label {
  display: inline-block;
  margin-right: 0.5rem;
}

.modal-card {
  fieldset {
    margin-bottom: 1.5rem;
  }
  legend {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
}
</style>
